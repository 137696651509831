.general-info {
  margin-bottom: 0;
  padding-bottom: 4rem;
}

.handle {
  display: block;
  font-size: 3rem;
  font-style: italic;
  padding: 1rem;
}