#hero {
    background: var(--community);
    overflow-y: hidden;
    padding: 2rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

#hero img {
    width: 100%;
}

.hero-container {
    padding: 1rem 0;
}

.hero-svg-container {
    width: 100%;
    padding: 0;
    margin: 0;
}

@media screen and (min-width: 841px) {
    .hero-iframe {
        width: 300px;
        height: 600px;
    }
}

@media screen and (max-width: 840px) {
    #hero {
        flex-direction: column-reverse;
    }

    .hero-iframe {
        width: 450px;
        height: 900px;
    }
}

@media screen and (max-width: 600px) {
    .hero-iframe {
        width: 300px;
        height: 600px;
    }
}