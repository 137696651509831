#festival-header {
  margin-top: 0;
}

#location-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2.location-name {
  margin-bottom: 0;
}

.band-container {
  padding: 2rem 0;
}

.location-table-container {
  padding: 4rem 1rem;
}

.festival-time {
  font-size: 24px;
}

.location-table-row {
  padding: 1rem 0;
}

.volunteer-hero-container .button {
  margin-top: 1rem;
}

td {
  padding: 2rem 0;
}

h1 {
  margin: 0;
  padding-bottom: 1rem;
}